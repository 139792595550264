@import url("./normalize.css");

/*
 * @media steps:
 * 1240
 * 1020
 * 800
 * 480
 */


:root {
  --primary-font: Roboto, sans-serif;
  --secondary-font: Teko, sans-serif;
  --mono-font: 'Roboto Mono', monospace, sans-serif;

  --primary-weight-normal: 400;
  --primary-weight-bold: 500;

  --mono-weight-normal: 400;
  --mono-weight-bold: 700;

  --page-width: 1240px;

  --spacing-1: 70px;
  --spacing-2: 50px;
  --spacing-3: 30px;
  --spacing-4: 15px;

  --size-secondary-1: 5.8rem;
  --size-secondary-2: 3.2rem;
  --size-secondary-3: 2.6rem;
  --size-secondary-4: 2.0rem;

  --size-primary-1: 1.8rem;
  --size-primary-2: 1.6rem;
  --size-primary-3: 1.4rem;
  --size-primary-4: 1.2rem;
  --size-primary-5: 1.1rem;
  --size-primary-6: 0.9rem;
}



/*------------------------------------*\
  #HTML-ELEMENTS
\*------------------------------------*/

* {
  outline: 0;
}


html {
  scrollbar-gutter: stable;
}

html,
body {
  background-color: var(--bg-main);
  color: var(--c-text-default);
  font-family: var(--primary-font);
  margin: 0;
  padding: 0;
  font-size: 10px;
  min-height: 100vh;
}


a,
a:active {
  text-decoration: none;
  color: var(--c-text-default);
  cursor: pointer;
}

a:hover {
  color: inherit;
}


p {
  margin: 0 0 0.5em;
  font-size: var(--size-primary-3);
  line-height: 1.5;
  max-width: 700px
}

button {
  background: inherit;
  border: 0;
  color: inherit;
  padding: 0;
  margin: 0;
  cursor: pointer;
  font-weight: inherit;
}
button:focus {
  outline: none;
}

input::placeholder,
textarea::placeholder {
  color: var(--c-text-sub);
}

table {
  border-collapse: collapse;
  width: 100%;
}

th {
  text-align: left;
  font-weight: normal;
}


ul, ol, li {
  margin: 0;
  padding: 0;
  list-style-type: square;
  list-style-position: inside;
}

/*------------------------------------*\
  #HEADINGS
\*------------------------------------*/

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  font-weight: normal;
  line-height: 0.8;
}

h1 {
  font-family: var(--secondary-font);
  font-size: var(--size-secondary-1);
  margin: 30px 0 40px 0;
}

h2 {
  font-family: var(--secondary-font);
  font-size: var(--size-secondary-2);
  margin: 30px 0 10px 0;
  position: relative;
  top: 3px;
}

h3 {
  font-family: var(--secondary-font);
  font-size: var(--size-secondary-3);
  margin: 30px 0 5px 0;
}

.h-sep {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

/*------------------------------------*\
  #LINKS
\*------------------------------------*/

.link {
  text-decoration: underline;
}
.link:hover {
  color: var(--c-text-bright);
}


/**
 * Common link hover, so we don't
 * have to keep adding it to each class
 */
.lhv:hover {
  color: var(--c-text-bright);
}

/*------------------------------------*\
  #FONT-AWESOME
\*------------------------------------*/

.fa--inline {
  margin-right: 0.3rem;
  color: var(--c-text-sub);
}

/*------------------------------------*\
  #CONTENT
  Defines actual size and placement
  of content container element
\*------------------------------------*/

.content {
  margin: 0 auto;
  max-width: var(--page-width);
  width: 100%;
  box-sizing: border-box;
}

.content--columns {
  display: grid;
  grid-template-columns: [left] 9fr [right] 3fr [end];
  grid-column-gap: 20px;
  grid-row-gap: 0;
  padding-bottom: 50px;
}

.content--last {
  margin-bottom: var(--spacing-1);
}

@media (max-width: 1260px) {
  .content {
    max-width: 1260px;
    padding-left: 10px;
    padding-right: 10px;
  }
}


/*------------------------------------*\
  #OTHER-SECTIONS
\*------------------------------------*/

.section {
  overflow: auto;
  padding-bottom: 36px;
}

.content ~ .section {
  margin-top: 70px;
}


/*------------------------------------*\
  #COMMON-SPECIALS
\*------------------------------------*/

.cs-lnk {}
.cs-lnk:hover {
  color: white;
}

.cs-lnk--side {
  font-size: var(--size-primary-3);
  line-height: 2;
  border-bottom: 1px solid transparent;
}
.cs-lnk--new {
  text-transform: uppercase;
  font-size: var(--size-secondary-4);
  border-bottom: 1px solid transparent;
}
.cs-lnk--adow {
  display: inline-block;
  margin-left: 1em;
  border-bottom: 1px solid transparent;
}

.cs-lnk--b {
  border-color: var(--c-bundles);
}
.cs-lnk--b:hover {
  color: var(--c-bundles);
}

.cs-lnk--v {
  border-color: var(--c-vouchers);
}
.cs-lnk--v:hover {
  color: var(--c-vouchers);
}

.cs-lnk--g {
  border-color: var(--c-giveaway);
}
.cs-lnk--g:hover {
  color: var(--c-giveaway);
}

.cs-lnk--o {
  border-color: var(--c-miscdeal);
}
.cs-lnk--o:hover {
  color: var(--c-miscdeal);
}

/*------------------------------------*\
  #FORM
\*------------------------------------*/

/**
 * Input
 */
/* TODO rocket: this was originally styles for all input, but i forgot about checkboxes and such,
 *      can we simplify this and move to only where it's needed? */
.inpt {
  background: var(--bg-dark-3);
  border: 1px solid var(--border-light-1);
  color: var(--c-text-default);
  font-size: var(--size-primary-3);
  height: 3.8rem;
  padding: 0 1rem;
  margin-bottom: 1px;
  box-sizing: border-box;
}
.inpt:autofill {
  box-shadow: inset 4px 0 0 var(--c-notice);
  background: var(--bg-dark-3);
}
.inpt:hover {
  box-shadow: inset 4px 0 0 var(--border-light-3);
}
.inpt:focus {
  box-shadow: inset 4px 0 0 var(--c-highlight);
}

.form {}

.form__el + .form__el {
  margin-top: 15px;
}

.form__input {
  width: 100%;
  box-sizing: border-box;
}

.form__input--large {
  height: 4.3rem;
  font-size: 1.6rem;
}

.form__input--number {
  width: 80px;
  text-align: right;
}

.form__input--select {
  width: auto;
}

.form__input--date {
  width: 170px;
}

.form__input--error {
  box-shadow: inset 4px 0 0 var(--c-error);
  background: var(--c-error-input-bg);
}

.form__input--multiline {
  padding: 1rem;
  height: 10rem;
}
.form__input--tall {
  height: 20rem;
}


.form__label {
  font-size: var(--size-primary-3);
  cursor: pointer;
}

.form__label.is-disabled {
  color: var(--c-text-sub);
  cursor: default;
}

.form__label:not(.is-disabled):hover {
  color: white;
}

.form__group { /* when you need to group items */
  display: flex;
  align-items: center;
}

.form__group--spaced {
  justify-content: space-between;
  align-items: flex-start;
}

/**
 * Button
 */

.btn {
  color: var(--c-text-default);
  font-family: var(--secondary-font);
  font-size: var(--size-secondary-4);
  background-color: var(--c-btn-default);
  border: none;
  height: 50px;
  line-height: 50px;
  padding: 2px 40px 0 40px;
  cursor: pointer;
  margin-bottom: 1px;
  box-sizing: border-box;
  display: inline-block;
  white-space: nowrap;
}

.btn:hover {
  background-color: var(--c-btn-hover);
  color: var(--c-text-bright);
}
.btn:disabled {
  opacity: 0.3;
}

.btn + .btn:not(.btn--wide) {
  margin-left: 5px;
}

.btn--wide {
  width: 100%;
  text-align: center;
}

.btn--small {
  line-height: 35px;
  height: 35px;
}

.btn--simple {
  line-height: 25px;
  height: 25px;
  font-family: var(--primary-font);
  font-size: var(--size-primary-3);
}

.btn--secondary,
.btn--destruct {
  background-color: var(--bg-light-2);
}

.btn--destruct:hover {
  background-color: var(--c-error);
}

/**
 * Checkbox
 */
.chbox {
  display: flex;
  align-items: center;
  width: 100%;
  text-align: left;
  padding: 5px 0;
  margin-right: 16px;
}

.chbox::before {
  content: '\f0c8';
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  margin: 0 8px 0 0;
  color: var(--c-text-sub);
  font-size: 1.1em;
  display: inline-block;
  width: 14px;
}

.chbox:not(.is-disabled):hover::before {
  color: white;
}

input:checked + .chbox::before,
.chbox.is-active::before {
  content: '\f14a';
  color: var(--c-highlight);
}


/*------------------------------------*\
  #SPECIALS-FORM
\*------------------------------------*/

/*
 * Content Items
 * TODO move to components
 */

.sf-item {
  display: flex;
  border-bottom: 1px solid var(--border-dark-3);
  padding: 2px 15px;
  margin-left: -15px;
  margin-right: -15px;
  font-size: var(--size-primary-4);
  align-items: center;
  min-height: 39px;
}
.sf-item:hover {
  background: var(--bg-light-2);
}

.sf-item__main {
  flex-grow: 2;
  margin: 0 10px;
  line-height: 1.5;
}

.sf-item__title {
  font-size: var(--size-primary-3);
  margin-right: 1em;
}





.card {
  border: 1px solid var(--border-dark-1);
  border-radius: 4px;
  overflow: hidden;
}


.manager-item {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  gap: 1px;
  background: var(--bg-dark-1);
  border: 1px solid var(--bg-dark-1); /* note: intentional use of bg style */
  border-radius: 4px;
  margin-bottom: 1px;
  text-align: left;
  width: 100%;
  color: var(--c-text-sub);
  box-sizing: border-box;
  overflow: hidden;
}

.manager-item__btn {
  width: 55px;
  min-width: 55px;
  color: var(--c-text-sub);
  padding: 10px;
  background: var(--bg-light-1);
  white-space: nowrap;
  cursor: pointer;
  box-sizing: border-box;
  text-align: center;
}
.manager-item__btn--title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  text-align: left;
}


.manager-btn {
  background: var(--bg-light-1);
  border: 1px solid var(--border-light-1);
  border-radius: 4px;
  margin-bottom: 1px;
  text-align: left;
  width: 100%;
  color: var(--c-text-sub);
  box-sizing: border-box;
  padding: 10px;
  white-space: nowrap;
}
.manager-btn > span {
  font-size: var(--size-primary-4);
}

.manager-item__btn:hover,
.manager-btn:hover {
  background: var(--bg-light-2);
  color: white;
}


.flag-s {
  color: var(--mark-store) !important;
}
.flag-h {
  color: var(--mark-history) !important;
}
.flag-n {
  color: var(--mark-new) !important;
}


/*------------------------------------*\
  #ANIMATIONS
\*------------------------------------*/

@keyframes tilt-shaking {
  0% { transform: rotate(0deg); }
  25% { transform: rotate(5deg); }
  50% { transform: rotate(0deg); }
  75% { transform: rotate(-5deg); }
  100% { transform: rotate(0deg); }
}
